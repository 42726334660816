import { Fragment } from "react";
import { Link } from "react-router-dom";

export const Home = () => (
    <Fragment>
        <li><Link to="/">Home 01</Link></li>
    </Fragment>
);
export const Page = () => (
    <Fragment>
    <li><Link to="/about">About</Link></li>
    </Fragment>
);
export const Portfolio = () => (
    <Fragment>
    <li>
        <a href="https://vue.wessackenheim.com"><img src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/551ea4da-69e9-49d7-4881-8cba4717a000/icon" alt="vue portfolio" width="20" height="20"/> portfolio</a>
    </li>
    <li>
        <a href="https://angular.wessackenheim.com"><img src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/40addc43-5c6e-4fca-3438-f9c58ef47e00/icon" alt="angular portfolio" width="20" height="20"/> portfolio</a>
    </li>
    <li><Link to="/web-design">Website Design</Link></li>
    <li><Link to="/special-projects">Special Projects</Link></li>
    <li><Link to="/graphic-design">Graphic Design</Link></li>
    <li><Link to="/api">API</Link></li>
    <li><Link to="/blog">Contentful CMS</Link></li>
    </Fragment>
);
export const News = () => (
    <Fragment>
    <li><Link to="/news">News</Link></li>
    </Fragment>
);

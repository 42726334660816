import React from 'react';
import FooterThree from '../Footer/FooterOne';
import Header from '../Header/HeaderOne';
import { pageTitle, pageDescription } from '../PageTitle';
const SpecialProjects = () => {
    pageTitle('Special Projects');
    pageDescription(' I have been asked to complete special projects that were not a normal part of our business. These projects required that I learn a new skill quickly to complete the project promptly for our clients.');
    

    return (
        <>
        <Header></Header>
        <div id="main" className="portfolio__details-two section-padding">
            <div className="container">
                <div className="row">
                    <h1 className="mb-10 portTitle">Special Projects</h1> 
                    <p className="mb-30">Over the past 10 years, I have been asked to complete special projects that were not a normal part of our business. These projects required that I learn a new skill quickly to complete the project promptly for our clients. The hardest project was when we were asked to develop an SQL database and then visualize the data in a digestible way using tables and graphs. This project was critical for our client to keep providing services for JeldWen and Aldi. Below are a few other brief examples of special projects.</p>
                    <div className="col-xl-12 col-lg-12 lg-mb-30">
                        <div className="row mb-30">
                            <div className="col-xl-4 col-lg-4 lg-mb-30">
                                <div className="portfolio__details-two-left">
                                    <div className="all__sidebar specialSidebar mb-30 ">
                                        <div className="all__sidebar-item special details">
                                            <h4>Project Details</h4>
                                            <div className="all__sidebar-item-details">
                                                <div className="all__sidebar-item-details-list">
                                                    <h6>Client :</h6>
                                                    <span>Nest Homes</span>
                                                </div>
                                                <div className="all__sidebar-item-details-list">
                                                    <h6>Client Needs :</h6>
                                                    <span>Brochure Design</span><br></br>
                                                    <span>Sign Design</span>
                                                </div>
                                                <div className="all__sidebar-item-details-list">
                                                    <h6>Skills :</h6>
                                                    <span>Adobe Photoshop</span><br></br>
                                                    <span>Adobe Illustrator</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8">
                                <div className="row mb-30">
                                    <div className="col-sm-6 sm-mb-30">
                                        <img className="img__full" src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/b162891b-a42a-49a5-649c-d1ec58458c00/public" alt="Home elevation made in photoshop" />
                                    </div>
                                    <div className="col-sm-6">
                                        <img className="img__full" src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/789d58cf-b8c1-44d9-7643-16cff77e8800/public" alt="4x8 signs designed in Adobe Illustrator" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="col-xl-12 col-lg-12 lg-mb-30">
                        <div className="row mb-30">
                            <div className="col-xl-4 col-lg-4 lg-mb-30">
                                <div className="portfolio__details-two-left">
                                    <div className="all__sidebar specialSidebar">
                                        <div className="all__sidebar-item details special">
                                            <h4>Project Details</h4>
                                            <div className="all__sidebar-item-details">
                                                <div className="all__sidebar-item-details-list">
                                                    <h6>Client :</h6>
                                                    <span>Kontek Industries</span>
                                                </div>
                                                <div className="all__sidebar-item-details-list">
                                                    <h6>Client Needs :</h6>
                                                    <span>Custom design platform</span><br></br>
                                                    <span>Custom quote ability</span>
                                                </div>
                                                <div className="all__sidebar-item-details-list">
                                                    <h6>Skills :</h6>
                                                    <span>React.js</span><br></br>
                                                    <span>Hubspot</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8">
                                <div className="row mb-30">
                                    <div className="col-sm-6 sm-mb-30">
                                        <img className="img__full" src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/8cd40cc3-f5ab-4c34-00f6-0bdc54907e00/public" alt="Shoot House Configurator" />
                                    </div>
                                    <div className="col-sm-6">
                                        <img className="img__full" src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/2687b58d-9579-4253-7428-1bb3a6f24600/public" alt="Shoot House Panels" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        <FooterThree></FooterThree>            
        </>
    );
};

export default SpecialProjects;
import React from 'react';
import FooterThree from '../Footer/FooterOne';
import Header from '../Header/HeaderOne';
import { pageTitle, pageDescription } from '../PageTitle';

const Portfolio = () => {
    pageTitle('Web Design');
    pageDescription('Here are a few screenshots of the homepages for websites that I have built for my former clients. I have built these on a variety of platforms.');
    return (
        <>
            <Header></Header>
            <div id="main" className="portfolio__column section-padding">
                <div className="container">
                    <div className="row"> 
                    <h1 className="mb-30 portTitle">Web Development</h1> 
                    <p className="mb-30">Most of these websites were designed on platforms that the client was familiar with and were designed in conjunction with their specifications. At times clients gave me the freedom to create
                    the sites on platforms of my choice and more design freedom. No matter which system the site was designed on I would make sure they were optimized for all screen sizes, SEO, and for speed. At times the biggest challenge 
                    came when clients required lots of 3rd party scripts to be installed for vaious tracking. This would ultimately cause issues with loading speed which I had to find ways to overcome.</p>
                        <div className="col-xl-12 mb-30">
                            <div className="portfolio__column-item">
                                <img className="img__full" src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/f72c1e15-daea-43c0-3562-633a7cf9c400/public" alt="K2 Scientific" />
                                <div className="portfolio__column-item-content">
                                    <span>Ecommerce</span>
                                    <h4>Shopify</h4> 
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 mb-30">
                            <div className="portfolio__column-item">
                                <img className="img__full" src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/9dad2737-4ac7-40f9-6981-5d2e23934c00/public" alt="MedWaste Nation" />
                                <div className="portfolio__column-item-content">
                                    <span>Ecommerce</span>
                                    <h4>Wordpress</h4> 
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 mb-30">
                            <div className="portfolio__column-item">
                                <img className="img__full" src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/68a78763-18f8-4561-8db9-0934a7e07400/public" alt="Art's Cube" />
                                <div className="portfolio__column-item-content">
                                    <span>Headless</span>
                                    <h4>Gatsby</h4> 
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 mb-30">
                            <div className="portfolio__column-item">
                                <img className="img__full" src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/f7ca9576-d1b7-4d81-014c-c7832a7c2600/public" alt="Endro Scientific" />
                                <div className="portfolio__column-item-content">
                                    <span>Ecommerce</span>
                                    <h4>Hydrogen/Shopify</h4> 
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 mb-30">
                            <div className="portfolio__column-item">
                                <img className="img__full" src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/8582f211-f353-4ef7-b733-98f6134ddb00/public" alt="LakeMist Homes" />
                                <div className="portfolio__column-item-content">
                                    <span>Html/CSS/JS</span>
                                    <h4>Accrisoft</h4> 
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 mb-30">
                            <div className="portfolio__column-item">
                                <img className="img__full" src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/fce128c6-029f-4260-a9a1-109e16dd6200/public" alt="Justice for Camp Lejuene" />
                                <div className="portfolio__column-item-content">
                                    <span>Html/CSS/JS</span>
                                    <h4>Accrisoft</h4> 
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 mb-30">
                            <div className="portfolio__column-item">
                                <img className="img__full" src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/0a1b737d-508a-416f-1424-d68d74a1b900/public" alt="Kontek Industries" />
                                <div className="portfolio__column-item-content">
                                    <span>HTML/CSS/JS</span>
                                    <h4>Hubspot CMS</h4> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterThree></FooterThree>
        </>
    );
};

export default Portfolio;
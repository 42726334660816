import React, { useEffect, useState } from 'react';

const FetchGraphQLData = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36",
            "authorization": "-jW0bqArCidNr8a4aWzBoivnlvVjiUyO8yP__ovoVA0.eyJpbnN0YW5jZUlkIjoiNTE0ODFiODktZWMxNi00OWUwLThjZDQtMTZlN2IwYjc3Njc3IiwiYXBwRGVmSWQiOiIxMzgwYjcwMy1jZTgxLWZmMDUtZjExNS0zOTU3MWQ5NGRmY2QiLCJtZXRhU2l0ZUlkIjoiOTUzY2Y2MWEtZjk1ZC00ZDk4LWE5NzYtZjg3MTY1NDAwMmJhIiwic2lnbkRhdGUiOiIyMDI0LTA2LTA1VDEzOjIyOjAxLjA4NVoiLCJ2ZW5kb3JQcm9kdWN0SWQiOiJzdG9yZXNfc2lsdmVyIiwiZGVtb01vZGUiOmZhbHNlLCJhaWQiOiIzMDEzOTg2ZS1jODdmLTRlZTYtOGNkZi1iNzcwZjM1YWY2OWUiLCJiaVRva2VuIjoiYzQ3NGVkOTMtMTU0Yi0wNDc4LTI1YTItZWU5NmQ1Zjc3NGNkIiwic2l0ZU93bmVySWQiOiI2M2U5OTk1Ny00YjNlLTQ3ODctYWI5OC1mYjA5OTJmMDNkOTQifQ",
            "x-xsrf-token": "1717593649|ajlwCOJEVKXl",
            "Referer": "https://www.ashesandarrows.com/_partials/wix-thunderbolt/dist/clientWorker.2c66ffe1.bundle.min.js",
            "Referrer-Policy": "strict-origin-when-cross-origin"
          },
          body: JSON.stringify({
            query: `
              query Products($filter: JSON!, $limit: Int!) {
                storesProductsV1Products(queryInput: { query: { filter: $filter, paging: { limit: $limit } } }) {
                  items {
                    name
                  }
                }
              }
            `,
            variables: {
              filter: {}, // Adjust filter as needed
              limit: 9
            }
          })
        };

        const response = await fetch('https://proxy.cors.sh/https://www.ashesandarrows.com/_api/wixstores-graphql-server/graphql', {
          headers: {
            'x-cors-api-key': 'temp_d7ad28b3d8ce63aeb960ad6ce9354d25'
          },
          ...requestOptions
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        setData(result.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <h1>Products:</h1>
      {data && data.storesProductsV1Products && data.storesProductsV1Products.items && (
        <ul>
          {data.storesProductsV1Products.items.slice(0, 9).map((item, index) => (
            <li key={index}>{item.name}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FetchGraphQLData;




const x = 2; 
let y = 4; 
function update(arg) 
    { 
        return Math.random() + y * arg; 
    }; 
y = 2;
//Add Js statement below

//add js statement above.
const results = update(x);
console.log(results);
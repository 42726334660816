import React, { useState } from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import CountUp from 'react-countup';
import FooterThree from '../Footer/FooterOne';
import Header from '../Header/HeaderOne';
import { pageTitle } from '../PageTitle';

const AboutPage = () => {
    pageTitle('About Me');
    const [skillBar, setSkillBar] = useState(false);
    
    
    return (
        <>
            <Header></Header>                
            <ScrollTrigger onEnter={()=> setSkillBar(true)} onExit={()=> setSkillBar(false)}>
            <div id="main" className="about__page section-padding pb-100">
                <div className="container">
                    <div className="row mb-60">
                        <div className="col-xl-6 col-lg-8">
                            <div className="about__page-title">
                                <span className="subtitle">About</span>
                                <h2>Wes Sackenheim</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-100">
                        <div className="col-xl-12">
                            <div className="about__page-area">
                                <div className="about__page-area-experience">
                                    <h3><span className="counter">{skillBar && <CountUp start={0} end={10} duration={3} delay={0}></CountUp>}+</span></h3>
                                    <p>Years of Design Experience</p>
                                </div>
                                <img src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/7f652aec-92db-4ac0-4cd3-9af3e79f4e00/public" alt="Family" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <p>​I am a dedicated family man with a solid commitment to my two sons and a supportive wife. Outside of my professional endeavors, I have a passion for outdoor activities, particularly trout fishing and trail running. Over the past nine years, I co-founded and successfully operated a digital marketing company alongside two close associates. We encountered numerous challenges throughout our journey, yet I am most proud of our unwavering camaraderie amidst adversity. Regrettably, due to personal circumstances, we have decided to conclude our operations as Art's Cube. While transitioning from this chapter is bittersweet, I am eager to embrace new opportunities on the horizon.</p>
                    </div>
                </div>
            </div>
            </ScrollTrigger>
            
            
            <FooterThree></FooterThree>
        </>
    );
};

export default AboutPage;
import React from 'react';
import FooterThree from '../Footer/FooterOne';
import Header from '../Header/HeaderOne';
import { pageTitle, pageDescription } from '../PageTitle';

const Resume = () => {
    pageTitle('Contact Me');
    pageDescription('Feel free to give me a call or fill out the form. I will recieve a notification as soon as you submit the form and will get back with you as soon as I can.');
    return (
        <>
        <Header></Header>
        <div id="main" className="contact__area section-padding">
            <div className="container">
                <div className="row mb-60">
                    <object className="resumePDF" data="assets/img/Wes_Sackenheim.pdf" height="800" aria-label="Wes Sackenheim Resume"/>
                </div>
            </div>
        </div>
        <FooterThree></FooterThree>
        </>
    );
};

export default Resume;
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
const ProjectStyleTwoData = [    
    {
        id: uuidv4(),
        image: 'https://images.wessackenheim.com/CPA-postcard.jpg',
        category: 'print',
        name: 'Postcard',
    },    
    {
        id: uuidv4(),
        image: 'https://images.wessackenheim.com/bowtie.png',
        category: 'logo',
        name: 'Company Logo',
    },    
    {
        id: uuidv4(),
        image: 'https://images.wessackenheim.com/astronaut.jpg',
        category: 'photoshop',
        name: 'For fun',
    },    
    {
        id: uuidv4(),
        image: 'https://images.wessackenheim.com/Cromwell.png',
        category: 'logo',
        name: 'Company Logo',
    },    
    {
        id: uuidv4(),
        image: 'https://images.wessackenheim.com/MultiFamily.png',
        category: 'logo',
        name: 'Company Logo',
    },    
    {
        id: uuidv4(),
        image: 'https://images.wessackenheim.com/medwaste.png',
        category: 'logo',
        name: 'Company Logo',
    },    
    {
        id: uuidv4(),
        image: 'https://images.wessackenheim.com/endro.png',
        category: 'logo',
        name: 'Company Logo',
    },    
    {
        id: uuidv4(),
        image: 'https://images.wessackenheim.com/eve.png',
        category: 'photoshop',
        name: 'Colorize old photo',
    },    
    {
        id: uuidv4(),
        image: 'https://images.wessackenheim.com/HZ-11.jpg',
        category: 'photoshop',
        name: 'Home Elevation',
    },
    {
        id: uuidv4(),
        image: 'https://images.wessackenheim.com/foxChase.jpg',
        category: 'photoshop',
        name: 'Neighborhood lot map',
    },
];

const ProjectStyleTwoItem = ({ image, category, name }) => {
    return (
        <>
            <div id="main" className="col-lg-4 portfolio__area-two-col col-md-6 portfolio-item mt-30">
                <div className="portfolio__area-two-item"> 
                    <img className="img__full portfolio__area-two-item-img" src={image} alt={name} />
                    <div className="portfolio__area-two-item-content"> 
                        <span>{category}</span>
						<h4>{name}</h4> 
                    </div>
				</div>
            </div>
        </>
    )
}
const CategoryBtn = ({ name, handleSetCategory, label, className }) => {
    return (
        <>
            <button className={className} type="button" onClick={() => handleSetCategory(name)}>
                {label}
            </button>
        </>
    )
}

const PortfolioFilter = () => {
    const [category, setCategory] = useState('all');
    const [projectStyleTwoItem, setProjectStyleTwoItem] = useState([]);
    useEffect(() => {
        category === 'all'
            ? setProjectStyleTwoItem(ProjectStyleTwoData)
            : setProjectStyleTwoItem(ProjectStyleTwoData.filter((data) => data.category === category));
    }, [category]);
    return (
        <div className="portfolio__area-two section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 mb-30">
                        <div className="portfolio__area-two-title"> 
                            <span className="subtitle-one">Portfolio</span>
                            <h1 className="portTitle">Graphic Design</h1> 
                        </div>
                        <div className="portfolio__area-two-btn">
                            <CategoryBtn className={category === 'all' ? 'active' : null} name="all" label="Show All" handleSetCategory={setCategory}/>
                            <CategoryBtn className={category === 'print' ? 'active' : null} name="print" label="Print" handleSetCategory={setCategory} />
                            <CategoryBtn className={category === 'logo' ? 'active' : null} name="logo" label="Logo" handleSetCategory={setCategory} />
                            <CategoryBtn className={category === 'photoshop' ? 'active' : null} name="photoshop" label="Photoshop" handleSetCategory={setCategory} />
                        </div>
                    </div>
                </div>
                <div className="row portfolio__area-two-row">
                    {projectStyleTwoItem.map(
                        (item, length) =>
                            length <= '9' && (
                                <ProjectStyleTwoItem
                                    key={item.id}
                                    image={item.image}
                                    name={item.name}
                                    category={item.category}
                                />
                            )
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default PortfolioFilter;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';



const Features = () => {
    const featuresData = [
      {
        title: 'UI/UX Design',
        subtitle: 'Design',
        imgUrl: 'https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/ac723263-8623-4d7d-3c95-1e57ad262800/feature',
        href: '/web-design',
      },
      {
        title: 'Graphic Design',
        subtitle: 'Design strategy',
        imgUrl: 'https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/4d7517b4-2f93-4f5c-09f3-2a186d7b9d00/feature',
        href: '/graphic-design',
      },
      {
        title: 'Logo Design',
        subtitle: 'Creative',
        imgUrl: 'https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/a213d45a-d989-437f-1bf2-87748546d600/feature',
        href: '/graphic-design',
      },
      {
        title: 'SEO Optimization',
        subtitle: 'Search',
        imgUrl: 'https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/b81ae427-6fcf-4ad4-d1ba-7f9de1575300/feature',
        href: '/web-design',
      },
    ];
    const [active, setActive] = useState(1);
  const handelActive = (index) => {
    setActive(index);
  };

  return (
    <div className="features__area">
      <div className="container-fluid px-0">
        <div className="row gx-0">
          <div className="col-xl-12">
            <Swiper
              slidesPerView={4}
              loop={true}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
                1400: {
                  slidesPerView: 4,
                },
              }}
              className="features-slider"
            >
              {featuresData.map((item) => (
                <SwiperSlide
                  className={`features__area-item ${active === item.title ? 'active' : ''}`}
                  key={item.title}
                  onMouseEnter={() => handelActive(item.title)}
                >
                  <img src={item.imgUrl} alt={item.title} width="100%" height="auto" />
                  <div className="features__area-item-content">
                    <div className="features__area-item-content-icon">
                      <Link to={item.href} aria-label={item.title}>
                        <i className="fal fa-plus"></i>
                      </Link>
                    </div>
                    <h2>
                      <Link to={item.href}>{item.title}</Link>
                    </h2>
                    <p>{item.subtitle}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
import React, { useState, useEffect } from 'react';
import FooterThree from '../Footer/FooterOne';
import Header from '../Header/HeaderOne';
import { pageTitle, pageDescription } from '../PageTitle';
import NewsSideBar from './NewsSideBar';
import axios from 'axios';

const NewsStandard = () => {
  pageTitle('News');
  pageDescription('Just a few more examples of using external data sources to build out webpages. I only have the weather being set for my location since I do not want to get into any privacy issues at this time.');
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: 'GET',
          url: 'https://api.thenewsapi.com/v1/news/top',
          params: {
            api_token: 'uEvbqgpx5Ma4ruJn201CLZ4zOatWnh2Hqk4SOH5S',
            locale: 'us',
            categories: 'tech, business',
            limit: 5
          }
        };

        const response = await axios.request(options);
        setNewsData(response.data.data);
      } catch (error) {
        console.error('Error fetching news data:', error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US');
    const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <>
      <Header></Header>
      <div id="main" className="blog__standard section-padding">
        <div className="container">
          <div className="row">
          <h1 className="mb-10 portTitle">Tech & Business News</h1> 
            <p className="mb-30">All of these articles are being pulled using thenewsapi.com's free top stories API. Being that this is a free API it is limited to 3 articles and 100 daily requests. I have also added a weather API from The Nation Weather Service to show the current weather at my location.</p>
            <hr></hr>
            <div className="col-xl-8 col-lg-8 lg-mb-60">
              {newsData.map((item) => (
                <div className="blog__standard-item" key={item.uuid}>
                  <div className="blog__standard-item-image">
                  <a href={item.url} target="_blank" rel="noreferrer">
                      <img src={item.image_url} alt={item.title} />
                    </a>
                  </div>
                  <div className="blog__standard-item-meta">
                    <ul>
                      <li>
                      <a href={item.url} target="_blank" rel="noreferrer">
                          <i className="far fa-calendar-alt"></i>
                          {formatDate(item.published_at)}
                        </a>
                      </li>
                      <li className="categories">
                          <i className="far fa-comments"></i>
                          {item.categories.join(', ')}
                      </li>
                      <li>
                      <a href={item.url} target="_blank" rel="noreferrer" className="newsSource">
                          <i className="far fa-copyright"></i>
                          {item.source}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="blog__standard-item-content">
                    <h3>
                      <a href={item.url} target="_blank" rel="noreferrer">
                        {item.title}
                      </a>
                    </h3>
                    <p>{item.description}</p>
                    
                  </div>
                </div>
              ))}
            </div>
            <div className="col-xl-4 col-lg-4">
              <NewsSideBar></NewsSideBar>
            </div>
          </div>
        </div>
      </div>
      <FooterThree></FooterThree>
    </>
  );
};

export default NewsStandard;

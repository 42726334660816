import React from 'react';
import { Link } from 'react-router-dom';
import FooterThree from '../Footer/FooterOne';
import Header from '../Header/HeaderOne';
import { pageTitle, pageDescription } from '../PageTitle';
import Form from './Form';

const Contact = () => {
    pageTitle('Contact Me');
    pageDescription('Feel free to give me a call or fill out the form. I will recieve a notification as soon as you submit the form and will get back with you as soon as I can.');
    return (
        <>
        <Header></Header>
        <div id="main" className="contact__area section-padding">
            <div className="container">
                <div className="row mb-60">
                    <div className="col-xl-5 col-lg-6">
                        <div className="contact__area-title">
                            <span className="subtitle">Contact Me</span>
                            <h1 className="portTitle">Feel free to fill out the form or give me a call</h1> 
                        </div>
                        <div className="contact__area-info">
                            <div className="contact__area-info-item">
                                <div className="contact__area-info-item-icon">
                                    <i className="far fa-phone-alt"></i>
                                    <span>Phone :</span>
                                </div>
                                <div className="contact__area-info-item-content">
                                    <a href="tel:704-980-9441">(704) 980-9441</a>
                                </div>
                            </div>
                            
                            <div className="contact__area-info-item">
                                <div className="contact__area-info-item-icon">
                                    <i className="far fa-map-marker-alt"></i>
                                    <span>Location :</span>
                                </div>
                                <div className="contact__area-info-item-content">
                                    <Link to="#">Troutman, NC</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="contact__area-bottom">
                            <div className="contact__area-bottom-form page">
                                <Form></Form>
                            </div>
                            <div className="contact__area-bottom-map">
                            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51849.128133542064!2d-80.87417665!3d35.687576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885156bc77de290f%3A0xaf6c5f4e3a367a85!2sTroutman%2C%20NC!5e0!3m2!1sen!2sus!4v1707849101024!5m2!1sen!2sus"loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterThree></FooterThree>
        </>
    );
};

export default Contact;
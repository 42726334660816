import React, { useState, useEffect } from 'react';

import axios from 'axios';

const NewsSideBar = () => {
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.weather.gov/gridpoints/GSP/115,86/forecast');
        setWeatherData(response.data.properties.periods);
      } catch (error) {
        console.error('Error fetching weather data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="all__sidebar ml-25 xl-ml-0">
        <div className="all__sidebar-item">
          <h4>Weather</h4>
          <div className="all__sidebar-item-category">
          {weatherData ? (
              <>
                <div key={weatherData[0].number} className="cardContainer">
                  <div className="card">
                    <p className="city">Troutman NC</p>
                    <p className="weather">{weatherData[0].shortForecast}</p>
                    <img className="weatherImg mt-10" src={weatherData[0].icon} alt="weather icon" />
                    <p className="temp mt-10">{weatherData[0].temperature}° {weatherData[0].temperatureUnit}</p>
                    <p className="forecast mt-10"><span>Forecast:</span> {weatherData[0].detailedForecast}</p>
                  </div>
                </div>
              </>
            ) : (
              <p>Loading weather data...</p>
            )}
          </div>
        </div>
            
        </div>            
        </>
    );
};

export default NewsSideBar;
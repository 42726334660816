import React from 'react';
import FooterThree from '../Footer/FooterOne';
import Header from '../Header/HeaderOne';
import Cars from './carAPI';
import FetchGraphQLData from './aaApi';
import { pageTitle, pageDescription } from '../PageTitle';

const PortfolioDetails = () => {
    pageTitle('Using APIs');
    pageDescription('This is just an example of an api I found through tracing the network in my browser. All of the buttons take you to the dealership page.');
    return (
        <>
            <Header></Header>
            <div id="main" className="portfolio__details section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 lg-mb-30">
                            <div className="all__sidebar mr-30 xl-mr-0">
                                <div className="all__sidebar-item details">
                                    <h4>Portfolio Details</h4>
                                    <div className="all__sidebar-item-details">
                                        <div className="all__sidebar-item-details-list">
                                            <h6>Skillset :</h6>
                                            <span>API</span>
                                        </div>
                                        <div className="all__sidebar-item-details-list">
                                            <h6>Platform :</h6>
                                            <span>React</span>
                                        </div>
                                        <div className="all__sidebar-item-details-list">
                                            <h6>Other :</h6>
                                            <span>Custom Integrations </span>
                                            <span>Alternative to web scraping</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8">
                            <div className="portfolio__details-left">
                                <div className="portfolio__details-left-content">
                                    <h2 className="mb-30">Below is a sample of content that is being pulled from an API that I found on a car dealerships website</h2>
                                    <p><em>I do know this company and have worked with them before.</em><br/>
                                        I found the API on their new inventory page and created a module to pull the information for 12 vehicles and display as you can see below.
                                        If you look at the source of the images you can see they are all being pulled from dealer.com which is one of the few hosting services that 
                                        manufacturers allow dealerships to use. If you click on any of the buttons it will open a new tab and take you to the actual dealership webpage 
                                        for that vehicle.  I am using a cors proxy that is free from <a href='https://corsproxy.io' target='_blank' rel='noreferrer'>CorsProxy.io</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 mt-30">
                        <FetchGraphQLData />
                            <Cars />
                        </div>
                    </div>
                    
                </div>
            </div>
            <FooterThree></FooterThree>
        </>
    );
};

export default PortfolioDetails;
import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import Papa from 'papaparse';

// Map container style
const containerStyle = {
  width: '100%',
  height: '900px'
};

// Default center of the map
const center = {
  lat: 37.7749,
  lng: -122.4194
};

// Function to generate the SVG as a data URI
const getSVGIcon = (color) => {
  const svg = `
<svg version="1.1" id="Main_Layer" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 112.2 112.2" style="enable-background:new 0 0 112.2 112.2;" xml:space="preserve" height="10" width="10">
<style type="text/css">
	.st0{fill:#282D30;}
	.st1{fill:#DFFE02;}
</style>
<g>
	<g>
		<circle class="st0" cx="56.1" cy="56.1" r="56.1"/>
		<g>
			<g>
				<path class="st1" d="M56.1,62.8c-9.5,0-17.2,7.7-17.2,17.2s7.7,17.2,17.2,17.2S73.3,89.5,73.3,80S65.6,62.8,56.1,62.8"/>
				<path class="st1" d="M82.1,44.6c-7-6-16.2-9.3-25.9-9.3c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-9.7,0-18.9,3.3-25.9,9.3
					c-0.3,0.2-0.6,0.7-0.6,1.3c0,0.9,0.4,1.4,0.4,1.4l3.3,4.1c0,0,0.5,0.6,1.3,0.7c0.6,0.1,1.1-0.2,1.3-0.3
					c6.3-4.7,13.3-7.2,20.3-7.2c6.9,0,14,2.4,20.3,7.2c0.2,0.2,0.7,0.4,1.3,0.3c0.8-0.1,1.3-0.7,1.3-0.7l3.3-4.1
					c0,0,0.4-0.5,0.4-1.4C82.7,45.4,82.4,44.9,82.1,44.6"/>
				<path class="st1" d="M72.7,56.4c-4.5-3.6-10.3-5.6-16.3-5.6c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-6.1,0-11.8,2-16.3,5.6
					c-0.4,0.3-0.7,0.9-0.7,1.4c0,0.8,0.4,1.3,0.4,1.3l1.4,1.7c0,0,0.4,0.6,1.3,0.7c0.5,0.1,1-0.1,1.4-0.4c4.1-2.9,8.6-4.3,12.9-4.3
					c4.4,0,8.9,1.4,12.9,4.3c0.3,0.2,0.8,0.4,1.4,0.4c0.8-0.1,1.3-0.7,1.3-0.7l1.4-1.7c0,0,0.4-0.5,0.4-1.3
					C73.4,57.3,73.1,56.7,72.7,56.4"/>
				<path class="st1" d="M93.2,29.3c-10-8.4-22.9-13-36.6-13c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
					c-0.1,0-0.2,0-0.2,0c-13.7,0-26.7,4.6-36.6,13c-0.2,0.2-0.9,0.9-1,2c-0.1,1.2,0.6,2.1,0.6,2.1l3.8,4.8c0.1,0.2,0.9,1.3,2.3,1.4
					c1.2,0.1,1.9-0.4,2-0.5c8.5-6.6,18.7-9.7,29.2-9.7c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c10.5,0,20.6,3.1,29.2,9.7
					c0.1,0.1,0.8,0.6,2,0.5c1.4-0.1,2.1-1.2,2.3-1.4l3.8-4.8c0,0,0.7-0.9,0.6-2.1C94.1,30.1,93.4,29.5,93.2,29.3"/>
			</g>
			<g>
				<path class="st0" d="M53.9,79c0,0,0.1,0,0.1,0h1c1.2,0,2.1-0.2,2.7-0.7c0.4-0.2,0.6-0.6,0.8-1c0.2-0.4,0.3-0.9,0.3-1.4
					c0-1-0.3-1.7-1-2.3c-0.6-0.5-1.6-0.8-2.9-0.8h-1.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0.1v5.8C53.9,78.8,53.9,78.9,53.9,79 M58.4,81.4
					l5.4,7.2c0.3,0.3,0.4,0.6,0.4,0.7c0,0.2-0.1,0.4-0.2,0.5c-0.2,0.1-0.4,0.1-0.8,0.1h-2.5c-0.4,0-0.6-0.1-0.7-0.1
					c-0.1,0-0.3-0.2-0.5-0.5l-5.1-7.5h-0.5v7.1c0,0.5-0.1,0.7-0.2,0.8c-0.1,0.1-0.4,0.3-0.8,0.3H51c-0.5,0-0.7-0.1-0.8-0.3
					c-0.1-0.1-0.2-0.3-0.2-0.8V70.7c0-0.5,0.2-0.7,0.3-0.8c0.1-0.1,0.3-0.3,0.8-0.3h4.8c2.3,0,4,0.5,5.2,1.4c0.6,0.5,1.1,1.2,1.5,2
					c0.4,0.8,0.5,1.7,0.5,2.6c0,0.9-0.2,1.7-0.5,2.5c-0.3,0.8-0.8,1.4-1.3,1.9C60.5,80.8,59.5,81.2,58.4,81.4"/>
			</g>
		</g>
	</g>
</g>
</svg>
  `;
  
  return {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
    scaledSize: new window.google.maps.Size(10, 10), // Size of the marker
  };
};

const GoogleMapsWithSVGMarkers = () => {
  const [locations, setLocations] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null); // Track selected marker

  useEffect(() => {
    const fetchCSVData = async () => {
      const response = await fetch('/locations.csv');
      const csvData = await response.text();
      
      Papa.parse(csvData, {
        header: true,
        dynamicTyping: true,
        complete: (results) => {
          const parsedLocations = results.data.map(row => ({
            id: row['AEX id'], // Parse the AEX id column
            address: row['Address'],
            city: row['City'],
            state: row['State'],
            contact: row['Associated Contact'],
            status: row['Status'],
            lat: row['Latitude'],
            lng: row['Longitude']
          }));
          setLocations(parsedLocations);
        }
      });
    };

    fetchCSVData();
  }, []);

  return (
    <div>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={4}
        >
          {locations.map((location, index) => (
            <Marker
              key={index}
              position={{ lat: location.lat, lng: location.lng }}
              icon={getSVGIcon(index % 2 === 0 ? '#FF0000' : '#00FF00')} // Alternating between red and green markers
              onClick={() => setSelectedMarker(location)} // Set selected marker on click
            />
          ))}

          {selectedMarker && (
            <InfoWindow
              position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
              onCloseClick={() => setSelectedMarker(null)} // Close the InfoWindow
            >
              <div className="mapInfoBox">
                <h4>{selectedMarker.address}</h4>
                <p><strong>ID:</strong> {selectedMarker.id}</p>
                <p><strong>City:</strong> {selectedMarker.city}, {selectedMarker.state}</p>
                <p><strong>Contact:</strong> {selectedMarker.contact}</p>
                <p><strong>Status:</strong> {selectedMarker.status}</p>
                <p><strong>Coordinates:</strong> {selectedMarker.lat}, {selectedMarker.lng}</p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default GoogleMapsWithSVGMarkers;

import React from 'react';
import { Link } from 'react-router-dom';

const Banner = () => {
    return (
        <div className="banner__area" id="main" style={{backgroundImage: `url('https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/f13a499d-f3bf-478e-d2a0-38c1ab20f300/public')`}}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="banner__area-content" role="contentinfo" aria-label="main">
                            <h1>Frontend Software <br /><span>Engineer</span></h1> 
                            <p className="bannerText">Unfortunately I'm not a real astronaut but I can use Photoshop</p>
                            <Link className="theme-btn" to="assets/img/Wes_Sackenheim.pdf">My Resume <i className="fal fa-long-arrow-right"></i></Link> 
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default Banner;
import React, { useState } from 'react';

const Form = () => {
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState('');
  const [showForm, setShowForm] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const email = formData.get('email');
    const enteredName = formData.get('name');
    
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!emailPattern.test(email)) {
      console.error('Invalid email address');
      return;
    }
  
    try {
      const response = await fetch('https://webhook.site/Wes', {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json'
        },
      });
  
      if (!response.ok) {
        console.error('Failed to submit the form');
        return;
      }
  
      setSubmitted(true);
      setName(enteredName);
      setShowForm(false);
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };

  return (
    <>
      {showForm && !submitted && (
        <form onSubmit={handleSubmit} method="post">
          <div className="row">
            <div className="col-sm-12 mb-25">
              <div className="contact__area-bottom-form-item">
                <input type="text" name="name" placeholder="Your Name" autoComplete="on" required />
              </div>
            </div>
            <div className="col-sm-12 mb-25">
              <div className="contact__area-bottom-form-item">
                <input type="email" name="email" placeholder="Email Address" autoComplete="on" required />
              </div>
            </div>
            <div className="col-sm-12 mb-25">
              <div className="contact__area-bottom-form-item">
                <input type="tel" name="phone" placeholder="Phone Number" autoComplete="on" required />
              </div>
            </div>
            <div className="col-sm-12 mb-25">
              <div className="contact__area-bottom-form-item">
                <input type="text" name="subject" placeholder="Subject" autoComplete="on" required />
              </div>
            </div>
            <div className="col-sm-12 mb-30">
              <div className="contact__area-bottom-form-item">
                <textarea name="message" rows="5" placeholder="Message"></textarea>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="contact__area-bottom-form-item">
                <button className="theme-btn-1" type="submit">Submit Now<i className="far fa-long-arrow-right"></i></button>
              </div>
            </div>
          </div>
        </form>
      )}
      {submitted && (
        <div>
          <p>Thank you, {name}, for reaching out to me! I will be in contact with you soon.</p>
        </div>
      )}
    </>
  );
};

export default Form;

import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const Cars = () => {
    const [inventoryData, setInventoryData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const requestOptions = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36"
                    }
                };

                const response = await fetch('https://proxy.cors.sh/https://www.teammorganton.com/apis/widget/INVENTORY_LISTING_DEFAULT_AUTO_NEW:inventory-data-bus1/getInventory', {
                  headers: {
                  'x-cors-api-key': 'temp_d7ad28b3d8ce63aeb960ad6ce9354d25'
                  }
                }, requestOptions);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json(); // Parse JSON response
                const firstTenCars = result.inventory.slice(0, 12); // Limit to first 10 cars
                setInventoryData(firstTenCars); // Set only the first 10 cars
            } catch (error) {
                console.error("Fetch error: ", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <h1 className="portTitle">Inventory Data</h1>
            <div className="card-container">
                {Array.isArray(inventoryData) && inventoryData.map((car, index) => (
                    <div className="carCard" key={index}>
                        <h3>{car.year} {car.make} {car.model} {car.trim}</h3>
                        <img src={car.images[0]?.uri} alt={car.title[0]} />
                        <Tabs>
                            <TabList>
                                <Tab>Pricing</Tab>
                                <Tab>Info</Tab>
                            </TabList>

                            <TabPanel>
                                <p><span>MSRP:</span>{car.pricing.retailPrice}</p>
                                {car.pricing?.dPrice.find(price => price.isDiscount)?.value && (
                                    <p><span>Discount:</span> {car.pricing.dPrice.find(price => price.isDiscount).value}</p>
                                )}
                                <hr/>
                                {car.pricing?.dPrice.find(price => price.isFinalPrice)?.value && (
                                    <p><span>Final Price:</span> {car.pricing.dPrice.find(price => price.isFinalPrice).value}</p>
                                )}
                            </TabPanel>
                            <TabPanel>
                                <p><span>Engine:</span> {car.attributes.find(attr => attr.name === "engine")?.value}</p><br/>
                                <p><span>Fuel Economy:</span> {car.attributes.find(attr => attr.name === "fuelEconomy")?.value}</p><br/>
                                <p><span>Features:</span> {car.packageNames}</p>
                            </TabPanel>
                        </Tabs>
                        <a className="theme-btn" href={`https://www.teammorganton.com${car.link}`} target='_blank' rel='noreferrer' >View <i className="fal fa-long-arrow-right"></i></a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Cars;

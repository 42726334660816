import React from 'react';
import { Link } from 'react-router-dom';

const Services = () => {
    const servicesData = [
      {
        id: 1,
        title: 'Website Design',
        batch: '01',
        description: 'Experience with WordPress, Hubspot, Shopify, BigCommerce, React.js, Gatsby, Html, Hydrogen, JS & CSS',
        imgOne: 'https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/19051ac9-3f04-4d4f-aba1-64391fadb700/base',
        imgTwo: 'https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/32abec4c-8bce-4f82-93d8-16b2a85d8d00/base',
        href: '/web-design',
      },
      {
        id: 2,
        title: 'Adobe Suite',
        batch: '02',
        description: 'Proficient using Photoshop, Illustrator, Dreamweaver, InDesign, Premier Pro',
        imgOne: 'https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/3118876f-50ed-470f-c548-7afa73920e00/base',
        imgTwo: 'https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/b39ec8b4-81d2-4ae9-c9ee-49f6fca3d800/base',
        href: '/graphic-design',
      },
      {
        id: 3,
        title: 'Hubspot',
        batch: '03',
        description: 'Extensive knowledge of most aspects of Hubspot along with several certifications',
        imgOne: 'https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/373b4b7a-c092-4541-a5a5-390b19166800/base',
        imgTwo: 'https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/71c9379f-1ccc-42e9-6d34-5105fec10f00/base',
        href: '/special-projects',
      },
      {
        id: 4,
        title: 'Custom Integrations',
        batch: '04',
        description: 'Saved clients time and money by creating integrations using webhooks and APIs',
        imgOne: 'https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/b0596d0f-de9a-45f9-bf17-f9c7c8c43c00/base',
        imgTwo: 'https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/933f7b4a-5ebf-4046-9f9f-5c733a5c2a00/base',
        href: '/special-projects',
      },
    ];
    return (
        <div className="services__area section-padding" style={{backgroundImage: `url('https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/f1ceaf58-648a-491d-06e2-15801b959300/public')`}}>
            <div className="container">
                <div className="row mb-30">
                    <div className="col-xl-12">
                        <div className="services__area-title lg-t-left"> 
                            <span className="subtitle one">Services</span>
                            <h2>Past Experience</h2> 
                        </div>
                    </div>
                </div>
                <div className="row">
                    {servicesData.map((item, id)=>(
                        <div className="col-xl-6 col-lg-6 col-md-6" key={id}>
                            <div className="services__area-item"> 
                                <span>{item.batch}</span>
                                <div className="services__area-item-icon">
                                    <img src={item.imgTwo} alt={item.title} />
                                    <div className="services__area-item-icon-one">
                                        <img src={item.imgOne} alt={item.title} />
                                    </div>
                                </div>
                                <div className="services__area-item-content">
                                    <h3><Link to={item.href}>{item.title}</Link></h3>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Services;
export const pageTitle = (title) => {
  return ( document.title = title + " - Personal Portfolio");
};
export const pageDescription = (description) => {
  // Truncate description if it exceeds 130 characters
  const truncatedDescription = description.length > 130 ? description.substring(0, 130) : description;

  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute('content', truncatedDescription);
  } else {
    const newMetaTag = document.createElement('meta');
    newMetaTag.setAttribute('name', 'description');
    newMetaTag.setAttribute('content', truncatedDescription);
    document.head.appendChild(newMetaTag);
  }
};
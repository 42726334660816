import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { createClient } from 'contentful';
import { Link } from 'react-router-dom';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import FooterThree from '../Footer/FooterOne';
import Header from '../Header/HeaderOne';
import { pageTitle, pageDescription } from '../PageTitle';
import NewsSideBar from '../News/NewsSideBar';

const client = createClient({
    space: 'wu4y9c0r968j',
    accessToken: '3L7GLf6JyZGpd47oLxVxXIzgxx63pCBKhUHZFlMjoLA',
});

const NewsDetails = () => {
    const { id } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        console.log('Fetching post with id:', id);
        client.getEntry(id)
            .then((response) => {
                console.log('Fetched post:', response.fields);
                setPost(response.fields);
                pageTitle(response.fields.title);
            })
            
            .catch(console.error);
    }, [id]);
    console.log(post);

    function formatDate(dateString) {
        const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }
    if (!post) return "Loading...";

    // Convert blog body to HTML
    const blogBodyHtml = documentToHtmlString(post.blogBody);
    pageDescription(blogBodyHtml);
    return (
        <>
            <Header></Header>        
            <div id="main" className="blog__details section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 lg-mb-30">
                            <div className="blog__details-left">
                                <img src={post.image.fields.file.url} alt={post.title} />
                                <div className="blog__details-left-meta">
                                    <ul>
                                        <li><Link><i className="fal fa-user"></i>Wes Sackenheim</Link></li>
                                        <li><Link><i className="fal fa-calendar-alt"></i>{formatDate(post.date)}</Link></li>
                                    </ul>
                                </div>
                                <h1 className="mb-30 postTitle">{post.title}</h1>
                                <div className="postBody" dangerouslySetInnerHTML={{ __html: blogBodyHtml }}></div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                            <NewsSideBar></NewsSideBar>
                        </div>
                    </div>
                </div>
            </div>
            <FooterThree></FooterThree>
            
        </>
    );
};


export default NewsDetails;

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutPage from './Pages/AboutPage/AboutPage';
import Contact from './Pages/Contact/Contact';
import Resume from './Pages/Resume/ResumePage';
import Error from './Pages/Error/Error';
import HomeOne from "./Pages/HomeOne/HomeOne/HomeOne";
import NewsStandard from './Pages/News/NewsStandard';
import Blog from './Pages/Blog/BlogStandard';
import Portfolio from './Pages/Portfolio/Portfolio';
import PortfolioDetails from './Pages/Portfolio/PortfolioDetails';
import GraphicDesign from './Pages/Portfolio/GraphicDesign';
import SpecialProjects from './Pages/Portfolio/SpecialProjects';
import PortfolioTwo from './Pages/Portfolio/PortfolioTwo';
import ScrollTop from "./Pages/ScrollTop/ScrollTop";
import PostPage from './Pages/Blog/BlogPost';
import MapPage from './Pages/MapPage/MapPage';


function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<HomeOne />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/web-design" element={<Portfolio />} />
                    <Route path="/portfolio-two" element={<PortfolioTwo />} />
                    <Route path="/graphic-design" element={<GraphicDesign />} />
                    <Route path="/api" element={<PortfolioDetails />} />
                    <Route path="/special-projects" element={<SpecialProjects />} />
                    <Route path="/news" element={<NewsStandard />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/resume" element={<Resume />} />
                    <Route path="/map" element={<MapPage />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:id" element={<PostPage />} /> 
                    <Route path="*" element={<Error />} />
                </Routes>
            </Router>
            <ScrollTop />
        </>
    )
}

export default App;

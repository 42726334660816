import React from 'react';
import FooterOne from '../../Footer/FooterOne';
import HeaderOne from '../../Header/HeaderOne';
import Banner from '../Banner/Banner';
import Features from '../Features/Features';
import Services from '../Services/Services';
import { pageTitle, pageDescription } from '../../PageTitle';

const HomeOne = () => {
    pageTitle('Wesley Sackenheim');
    pageDescription('This is a web app that I have created to provide examples of some past designs along with showcasing proficiency in areas that can not be conveyed through images.');
    return (
        <>
            <HeaderOne></HeaderOne>
            <Banner></Banner>
            <Features></Features>
            <Services></Services>        
            <FooterOne></FooterOne>
        </>
    );
};

export default HomeOne;
import React, { useState, useEffect } from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Link } from 'react-router-dom';
import FooterThree from '../Footer/FooterOne';
import Header from '../Header/HeaderOne';
import { pageTitle, pageDescription } from '../PageTitle';
import NewsSideBar from '../News/NewsSideBar';
import { createClient } from 'contentful';

const client = createClient({
  space: 'wu4y9c0r968j',
  accessToken: '3L7GLf6JyZGpd47oLxVxXIzgxx63pCBKhUHZFlMjoLA',
});

function Blog() {
  pageTitle('Contentful Blog');
  pageDescription('Here is some sample blog posts that were built on the Contentful CMS and are being pulled through via the API.');
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client.getEntries({ content_type: 'blogPost', include: 2 }) // Include linked assets up to 2 levels deep
      .then((response) => setPosts(response.items))
      .catch(console.error);
  }, []);
  function formatDate(dateString) {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
}


  return (
    <>
      <Header></Header>
      <div id="main" className="blog__standard section-padding">
        <div className="container">
          <div className="row">
          <h1 className="mb-10 portTitle">Contentful CMS</h1> 
            <p className="mb-30">This page is simply to show you that I was able to integrate a 3rd party CMS that is easier for others to make updates to the live site.
            I have started with Contentful but plan to add other platforms in the future.  </p>
            <div className="col-xl-8 col-lg-8 lg-mb-30">
            {posts.map((post) => {
                // Get image URL
                const imageUrl = post.fields.image.fields.file.url;

                // Convert blog body to HTML
                let blogBodyHtml = documentToHtmlString(post.fields.blogBody);

                // Limit blog body to 200 characters
                blogBodyHtml = blogBodyHtml.length > 200 ? blogBodyHtml.substring(0, 200) + "..." : blogBodyHtml;

                // Create URL slug from post title
                const slug = post.sys.id;

                return (
                  <div className="blog__standard-item" key={post.sys.id}>
                    <div className="blog__standard-item-image">
                    <Link to={`${slug}`}><img src={imageUrl} alt={post.fields.title} /></Link>
                    </div>
                    
                    <div className="blog__standard-item-content">
                      <h3><Link to={`${slug}`}>{post.fields.title}</Link></h3>
                      <div className="blog__standard-item-meta-list">
                        <ul>
                          <li><i className="far fa-calendar-alt"></i>{formatDate(post.fields.date)}</li>
                        </ul>
                      </div>
                      <Link to={`${slug}`}><div dangerouslySetInnerHTML={{ __html: blogBodyHtml }}></div></Link>
                    </div>
                  </div>
                );
              })}

            </div>
            <div className="col-xl-4 col-lg-4">
              <NewsSideBar></NewsSideBar>
            </div>
          </div>
        </div>
      </div>
      <FooterThree></FooterThree>
    </>
  );
};

export default Blog;

import React from 'react';
import FooterThree from '../Footer/FooterOne';
import Header from '../Header/HeaderOne';
import { pageTitle, pageDescription } from '../PageTitle';
import PortfolioFilter from './PortfolioFilter';

const Counter = () => {
    pageTitle('Graphic Design');
    pageDescription('Here are some examples of my work using the Adobe Creative Suite. You can see examples of print materials, logos, photo editing and some projects just for fun.');
    return (
        <>
        <Header></Header>
        <PortfolioFilter></PortfolioFilter>
        <FooterThree></FooterThree> 
        </>
    )
}

export default Counter;
import React from 'react';
import MapComponent from './MapComponent';
import Header from '../Header/HeaderOne';
import FooterThree from '../Footer/FooterOne';

function MapPage() {
  return (
    <>
      <Header></Header>
    <div className="App contact__area section-padding" id="main">
      <MapComponent />
    </div>
    <FooterThree></FooterThree>
    </>
  );
}

export default MapPage;

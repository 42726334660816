import React from 'react';
import { Link } from 'react-router-dom';

const FooterOne = () => {
    return (
        <>
            <footer className="footer__area section-padding" role='contentinfo' aria-label='contentinfo'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-7 col-lg-7">
                            <div className="footer__area-widget">
                                <div className="footer__area-widget-about lg-t-center">
                                    <div className="footer__area-widget-about-logo">
                                        <Link to="/"><img src="https://imagedelivery.net/DF926RUMiBgxrQOCj6Rcgg/706e9638-7ca8-4f68-6e16-8d8ac070f600/logo" alt="Wesley Sackenheim" /></Link>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5">
                            <div className="footer__area-widget">
                                <div className="footer__area-widget-about-menu">
                                    <ul role="menu">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about">About</Link></li>
                                        <li><Link to="/web-design">Web Design</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copyright__area copyright__area-one">
                <div className="container">
                    <div className="row align-items-center copyright__area-border">
                        <div className="col-xl-12">
                            <div className="copyright__area-left copyright__area-one-left">
                                <p>Copyright © 2024 Wesley Sackenheim</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FooterOne;